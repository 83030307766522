<template>
  <subcategory subcategoryName="Computers" :articles="articles"></subcategory>
</template>
<script charset="utf-8">
import subcategory from "@/components/subcategory.vue";

export default {
  components: {
    subcategory,
  },
  data() {
    return {
      articles: [
        {
          name: "Introduction to *Nix customization",
          description:
            "Welcome to the wonderful world of getting your computer look and feel cool",
          sneakPic: require("@/assets/article-sneak-pics/typing-faster.jpg"),
          sneakPicAlt: "keyboard",
          link: "/Articles/Productivity/typing-faster",
        },
      ],
    };
  },
};
</script>
